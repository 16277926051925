<template>
	<div>
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item label="日期" prop="time" :span="1.4">
				<el-date-picker
					v-model="formData.time"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="线路" prop="routeIds" :span="1.4">
				<el-select v-model="formData.routeIds" placeholder="请选择" multiple filterable>
					<el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<template #search>
				<el-button type="primary" v-on:click="search" v-if="btnexist('scheduleRoadBillInfoStatisticsList')">查询</el-button>
				<el-button type="primary" plain v-on:click="reset" v-if="true || btnexist('scheduleRoadBillInfoStatisticsReset')"
					>重置</el-button
				>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-if="btnexist('scheduleRoadBillInfoStatisticsExport')">导出</el-button>
			</template>
		</jy-query>
		<div style="font-size: 16px">
			搜索结果合计：<span style="color: red">{{ shiteNumTotal }}</span> 计划班次；
			<span style="color: red">{{ actualNumTotal }}</span>
			实际班次
		</div>
		<jy-table max-height="595" ref="jyTable" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="sd" label="开始日期" min-width="100"></jy-table-column>
			<jy-table-column prop="ed" label="结束日期" min-width="100"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路" min-width="100"></jy-table-column>
			<jy-table-column prop="shiteNum" label="计划班次数" min-width="80"></jy-table-column>
			<jy-table-column prop="actualNum" label="实际班次数" min-width="80"></jy-table-column>
			<jy-operate :list="butList"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<detailForRoute ref="detailForRoute"></detailForRoute>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
import detailForRoute from "@/components/pages/admin/roadBillInfo/statistics/detailForRoute.vue";
export default {
	data() {
		return {
			btnMenuId: "scheduleRoadBillInfoStatistics",
			formData: {
				time: [new Date().Format("yyyy-MM-dd"), new Date().Format("yyyy-MM-dd")],
				routeIds: []
			},
			dataList: [],
			butList: [],
			routeList: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			shiteNumTotal: 0,
			actualNumTotal: 0
		};
	},
	mixins: [btnMixins],
	components: { detailForRoute },
	created() {
		this.setButList();
		this.getRouteList();
	},
	activated() {
		this.getList();
	},
	methods: {
		setButList() {
			let list = [
				{
					name: "计划明细",
					fun: row => {
						console.log(row);
						let params = {
							routeId: row.routeId
						};
						if (this.formData.time.length) {
							params.startDate = this.formData.time[0];
							params.endDate = this.formData.time[1];
						}
						this.$refs.detailForRoute.init(params);
					},
					isShow: () => {
						return true || this.btnexist("scheduleRoadBillInfoAuditDetail");
					}
				}
			];
			this.butList = list;
		},
		reset() {
			this.$refs.checkForm.resetFields();
			this.getList();
		},
		search() {
			this.getList();
		},
		getList() {
			let url = "/waybillStatistics/statisticsByRouteId";
			let params = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			if (this.formData.time.length) {
				params.startDate = this.formData.time[0];
				params.endDate = this.formData.time[1];
			} else {
				this.$message({
					message: "请选择日期",
					type: "warning"
				});
				return;
			}
			if (this.formData.routeIds.length) {
				params.routeIds = this.formData.routeIds.join(",");
			}

			this.$http.post(url, params).then(({ detail }) => {
				this.total = detail.waybillStatisVOList.total;
				this.shiteNumTotal = detail.shiteNumTotal;
				this.actualNumTotal = detail.actualNumTotal;
				this.dataList = detail.waybillStatisVOList.list.map(one => {
					one.sd = params.startDate;
					one.ed = params.endDate;
					return one;
				});
			});
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
			});
		}
	}
};
</script>
