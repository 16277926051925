<template>
	<jy-dialog type="table" :title="title" :visible.sync="dialogVisible" appendToBody :width="'1000px'">
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item label="车牌号" prop="vehicleNo">
				<el-input placeholder="请输入" v-model="formData.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="自编号" prop="customNo">
				<el-input placeholder="请输入" v-model="formData.customNo"></el-input>
			</jy-query-item>
			<jy-query-item label="司机" prop="driverNa">
				<el-input placeholder="请输入" v-model="formData.driverNa"></el-input>
			</jy-query-item>
			<template #search>
				<el-button type="primary" v-on:click="search">查询</el-button>
				<el-button type="primary" plain v-on:click="reset">重置</el-button>
			</template>
		</jy-query>
		<div style="font-size: 16px">
			合计：<span style="color: red">{{ shiteNumTotal }}</span> 班次
		</div>
		<jy-table max-height="595" v-if="dialogVisible" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<!-- <jy-table-column prop="sd" label="开始日期" min-width="140"></jy-table-column>
            <jy-table-column prop="ed" label="结束日期" min-width="140"></jy-table-column> -->
			<jy-table-column prop="oDate" label="日期" min-width="100"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路" min-width="100"></jy-table-column>
			<jy-table-column prop="driverNa" label="司机" min-width="100"></jy-table-column>
			<jy-table-column prop="customNo" label="自编号" min-width="100"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="100"></jy-table-column>
			<jy-table-column prop="signInTime" label="签到时间" min-width="80"></jy-table-column>
			<jy-table-column prop="signOutTime" label="签退时间" min-width="80"></jy-table-column>
			<jy-table-column prop="shiteNum" label="班次数" min-width="80"></jy-table-column>
			<jy-operate :list="butList" width="100"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
	</jy-dialog>
</template>
<script>
export default {
	data() {
		return {
			title: "车辆班次记录",
			dialogVisible: false,
			butList: [],
			dataList: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			shiteNumTotal: 0,
			formData: {
				vehicleNo: "",
				customNo: "",
				driverNa: ""
			},
			option: {}
		};
	},
	methods: {
		setButList() {
			let list = [
				{
					name: "明细",
					fun: row => {
						this.$parent.$parent.dialogVisible = false;
						this.dialogVisible = false;
						console.log(row);
						let params = {
							driverNa: row.driverNa,
							routeId: row.routeId,
							oDate: row.oDate
						};
						this.$router.push({
							name: "roadBillInfoManagement",
							params: params
						});
					},
					isShow: () => {
						return true;
					}
				}
			];
			this.butList = list;
		},
		init(option) {
			this.formData = {
				...this.formData,
				...option
			};
			this.option = option;
			this.pageIndex = 1;
			this.pageSize = 10;
			this.getList();
			this.setButList();
			this.dialogVisible = true;
		},
		search() {
			this.getList();
		},
		reset() {
			this.formData = {
				vehicleNo: "",
				customNo: "",
				driverNa: "",
				...this.option
			};
			this.getList();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		getList() {
			let url = "/waybillStatistics/statisticsByOdateAndVehicleId";
			let params = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
				...this.formData
			};
			Object.keys(params).forEach(key => {
				if (/^\s*$/.test(params[key])) {
					delete params[key];
				}
			});
			this.$http.post(url, params).then(({ detail }) => {
				this.shiteNumTotal = detail.shiteNumTotal;
				this.total = detail.waybillStatisVOList.total;
				this.dataList = detail.waybillStatisVOList.list.map(one => {
					one.sd = params.startDate;
					one.ed = params.endDate;
					return one;
				});
			});
		}
	}
};
</script>
